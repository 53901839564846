import React, { useState } from 'react';
import './style.css';
import { handleError } from '../../utils/errors';

const IButton = ({ outline, ghost, size, color, children, onClick, disabled, className, ...rest }) => {
  const buttonClasses = `infurnia-button ${outline ? 'outline' : ''} ${ghost ? 'ghost' : ''} ${size ? size : 'medium'} ${color ? color : 'primary'} ${className ? className : ''}`;
  const [button_clicked, set_button_clicked] = useState({ status: false });

  const onClickButton = async (e) => {
    try{
      if (!disabled) {
        if (!(button_clicked && button_clicked.status)) {
          button_clicked.status = true;
          if(onClick){
            await onClick();
          }
          button_clicked.status = false;
        }
      }
    } catch(err){
      handleError(err)
      button_clicked.status = false;
    }
  }

  return (
    <button disabled={disabled} className={buttonClasses} onClick={onClickButton} {...rest}>
      {children}
    </button>
  );
};

export default IButton;
