export const get_sku_display_url = (sku_id) => {
    var base_path = JSON.parse(window.Module.get_sku_details_from_core(sku_id)).display_pic;
    if(base_path == ""){
        return "";
    }
    if(base_path.length>0 && base_path[0] == "/"){
        return window.Module.API.get_assets_path() + base_path;
    }else{
        return window.Module.API.get_assets_path() + "/" + base_path;
    }
}