import React, { useEffect, useState } from "react";
import { ICollpsable } from "../ICollpsable";
import { isNonEmptyArray } from "../../utils/array";
import { ApplicatorCard } from "../Modals";

const CamFlowRightPanelCard = ({ applicator }) => {
    const change_on_click = async () => {
        let resp = await window.Promisify(window.Module.open_single_cabinet_cam_mode_change_connector_modal('""'));
        window.handle_ui_response(resp);
    }

    const remove_on_click = async () => {
        let resp = await window.Promisify(window.Module.open_single_cabinet_cam_mode_remove_connector_modal('""'));
        window.handle_ui_response(resp);
    }

    const edit_on_click = async () => {
        var form = {}
        form["selected_connector_id"] = applicator.id;
        let resp = await window.Promisify(window.Module.open_single_cabinet_cam_mode_edit_connector_modal(JSON.stringify(form)));
        window.handle_ui_response(resp);
    }

    return (
        <div className="rounded" style={{ border: '1px solid #6B4EFF', overflow: 'hidden' }}>
            <ApplicatorCard edit_selected_applicator={edit_on_click} o={applicator} dropdown_buttons={[
                {
                    display_name: "Change connector",
                    customOnClick: change_on_click
                },
                {
                    display_name: "Remove connector",
                    customOnClick: remove_on_click,
                    color: '#FF5247'
                }
            ]} />
        </div>
    )
}

const CamFlowRightPanel = ({ cam_mode_data, update_view }) => {
    // const [ open_scenario, set_open_scenario ] = useState('');
    const toggle = (scenario) => {
        window.Module.set_cam_mode_active_connector_set(scenario.active ? '' : scenario.id);
        update_view();
        // set_open_scenario((prev) => {
        //     if(prev === scenario.id){
        //         return '';
        //     }else{
        //         return scenario.id;
        //     }
        // })
    }
    // useEffect(() => {
    //     console.log('open scenario', open_scenario)
    // }, [open_scenario]);
    return (
        <>
            <div style={{ textAlign: 'left', width: '400px', background: 'white', position: 'absolute', top: 'var( --navbar_height_design , 52px)', right: '4px', zIndex: '999', height: 'calc(100vh - var( --navbar_height_design , 52px))' }}>
                {
                    cam_mode_data && cam_mode_data.cam_mode_active_panel ?
                        <div style={{ height: '100%', flexDirection: 'column' }}>
                            <div className="inf-py-2 inf-px-5" style={{ fontWeight: 'bold' }}>Scenario List</div>
                            <div style={{ flex: 1, minHeight: '0px', overflow: 'auto' }}>
                                {
                                    cam_mode_data && cam_mode_data.scenarios && cam_mode_data.scenarios.length ? cam_mode_data.scenarios.map((scenario, idx) => (
                                        // <div className={`mb-3 p-2 mx-2 mes_panel`} style={{ border: ((scenario.active)) ? '2px solid #676878' : '', }} onClick={() => { window.Module.set_cam_mode_active_connector_set(scenario.id); update_view(); }}>
                                        //     <div className='mb-2' style={{ color: COLORS.gray9, fontWeight: 700 }}>{"Scenario " + (idx + 1)}</div>
                                        // </div>
                                        <ICollpsable header={`Scenario ${idx + 1}`} toggle={() => toggle(scenario)} isOpen={scenario.active}>
                                            <div className="flex_column inf-gap-2">
                                                {
                                                    isNonEmptyArray(scenario.cam_applicator_ids) ? scenario.cam_applicator_ids.map(cam_applicator_id => (
                                                        <CamFlowRightPanelCard applicator={cam_mode_data && cam_mode_data.connectors && cam_mode_data.connectors.find(x => x.id === cam_applicator_id)} />
                                                    )) : ''
                                                }
                                            </div>
                                        </ICollpsable>
                                    )) : ''
                                }
                            </div>
                        </div>
                        :
                        <div className="inf-p-2" style={{ height: '100%' }}>
                            <div className="flex_center" style={{ height: '100%', background: '#EAECF0', border: '2px dashed #A7A8B2' }}>
                                <div style={{ maxWidth: '250px', fontWeight: 500, color: '#676878', fontSize: '16px', textAlign: 'center' }}>
                                    Select a panel on 3D viewer or on panel listing in left panel to see the panel connectors here
                                </div>
                            </div>
                        </div>
                }
            </div>
        </>
    )
}

export default CamFlowRightPanel