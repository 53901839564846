import React, { useState, useEffect, isValidElement, useMemo, useRef } from 'react';

const _deref = (a) => (JSON.parse(JSON.stringify(a)));


const ConnectorCanvasComponent = ({curr_applicator, ui_action_callback, with_controller, canvas_width, canvas_height, panel_width, panel_depth, panel_thickness}) => {
    const canvasRef = useRef(null);

	const [activeElement, set_activeElement] = useState(null);

	const [activeTextBox, set_activeTextBox] = useState(null);

	const dimension_drop_in_mm = 10;

    useEffect(() => {
		draw_canvas();
	},[curr_applicator,canvas_width,canvas_height, activeElement])
	
	var distance_calc = (x1,y1,x2,y2) => {
		return Math.sqrt((x1 - x2)*(x1 - x2) + (y1 - y2)*(y1 - y2));
	}


	const findActiveElement = (clientX, clientY) => {
		var tol = 20;

		var new_activeElement = null;


		var type = curr_applicator.applicator_type;

		var connector_buttons = get_connector_buttons();

		for(var i=0;i<connector_buttons.length;i++){
			var curr_button = connector_buttons[i];
			if(distance_calc(curr_button.x,curr_button.y,clientX,clientY) < curr_button.radius){
				new_activeElement = {
					object: curr_button.key,
					x: curr_button.x,
					y: curr_button.y
				}
				break;
			}
		}

		if(new_activeElement == null && curr_applicator.application_method=="fixed_copies" && with_controller && (type == "dowel_hinge")){
			var eq_position = get_eq_position();

			if(distance_calc(eq_position.x, eq_position.y, clientX, clientY) < tol){
				new_activeElement = {
					object: "eq_button",
					x: eq_position.x,
					y: eq_position.y,
					val: !(curr_applicator.equal_distance)
				}
			}else if(!(curr_applicator.equal_distance)){
				var front_positions = get_filtered_offsets("front");
				var back_positions = get_filtered_offsets("back");

				var panel_origin = get_panel_origin();
				var pixel_per_mm = get_pixel_per_mm();

				var dimension_drop_amount = dimension_drop_in_mm*pixel_per_mm;
				var half_thickness = (panel_thickness/2)*pixel_per_mm;

				var pt = {
					x: panel_origin.x,
					y: panel_origin.y + half_thickness
				}

				for(var i=0;i<front_positions.length;i++){
					var dim_start = {x:pt.x,y:pt.y - (i+1)*dimension_drop_amount - half_thickness};
					var dim_end = {x:pt.x + pixel_per_mm*parse_offset_value(front_positions[i]),y:pt.y - (i+1)*dimension_drop_amount - half_thickness};

					var text_pos = {
						x: (dim_start.x + dim_end.x)/2,
						y: (dim_start.y + dim_end.y)/2
					}

					var dist = distance_calc(text_pos.x, text_pos.y, clientX, clientY)

					if(dist < tol){
						new_activeElement = {
							object: "offset",
							x: text_pos.x,
							y: text_pos.y,
							from: "front",
							curr_from: "front",
							idx: i,
							val: front_positions[i]
						}
					}
				}

				for(var i=0;i<back_positions.length;i++){
					var dim_start = {x:pt.x + pixel_per_mm*panel_width,y:pt.y + (i+1)*dimension_drop_amount + half_thickness};
					var dim_end = {x:pt.x + pixel_per_mm*panel_width - pixel_per_mm*parse_offset_value(back_positions[i]),y:pt.y + (i+1)*dimension_drop_amount + half_thickness};

					var text_pos = {
						x: (dim_start.x + dim_end.x)/2,
						y: (dim_start.y + dim_end.y)/2
					}

					var dist = distance_calc(text_pos.x, text_pos.y, clientX, clientY)

					var dimension_val = Math.sqrt((dim_start.x - dim_end.x)*(dim_start.x - dim_end.x) + (dim_start.y - dim_end.y)*(dim_start.y - dim_end.y));
					dimension_val /= get_pixel_per_mm();

					if(dist < tol){
						new_activeElement = {
							object: "offset",
							x: text_pos.x,
							y: text_pos.y,
							from: "back",
							curr_from: "back",
							idx: i,
							val: back_positions[i]
						}
					}
				}
			}
		}else if(new_activeElement == null && curr_applicator.application_method=="fixed_copies" && with_controller && (type == "single_panel_connector" || type == "cabinet_connector")){
			var back_left_positions = get_filtered_offsets("back","left");
			var back_right_positions = get_filtered_offsets("back","right");
			var front_right_positions = get_filtered_offsets("front","right");
			var front_left_positions = get_filtered_offsets("front","left");

			var panel_origin = get_panel_origin();
			var pixel_per_mm = get_pixel_per_mm();

			var back_left_idx = 0; var back_right_idx = 0; var front_right_idx = 0; var front_left_idx = 0;
			for(var i=0;i<curr_applicator.offsets.length;i++){
				var p, p_hori, p_vert;
				var curr_idx = 0;
				if(curr_applicator.offsets[i].from == "back" && curr_applicator.offsets[i].from1 == "left"){
					curr_idx = back_left_idx;
					p = {x: panel_origin.x + pixel_per_mm*parse_offset_value(back_left_positions[curr_idx].x), y: panel_origin.y + pixel_per_mm*parse_offset_value(back_left_positions[curr_idx].y)};
					p_hori = {x: panel_origin.x, y: p.y};
					p_vert = {x: p.x, y: panel_origin.y};
					back_left_idx += 1;
				}else if(curr_applicator.offsets[i].from == "back" && curr_applicator.offsets[i].from1 == "right"){
					curr_idx = back_right_idx;
					p = {x: panel_origin.x + pixel_per_mm*(panel_width - parse_offset_value(back_right_positions[curr_idx].x)),y: panel_origin.y + pixel_per_mm*parse_offset_value(back_right_positions[curr_idx].y)};
					p_hori = {x: panel_origin.x + pixel_per_mm*panel_width, y: p.y};
					p_vert = {x: p.x, y: panel_origin.y};
					back_right_idx += 1;
				}else if(curr_applicator.offsets[i].from == "front" && curr_applicator.offsets[i].from1 == "right"){
					curr_idx = front_right_idx;
					p = {x: panel_origin.x + pixel_per_mm*(panel_width - parse_offset_value(front_right_positions[curr_idx].x)), y: panel_origin.y + pixel_per_mm*(panel_depth - parse_offset_value(front_right_positions[curr_idx].y))}
					p_hori = {x: panel_origin.x + pixel_per_mm*panel_width, y: p.y};
					p_vert = {x: p.x, y: panel_origin.y + pixel_per_mm*panel_depth};
					front_right_idx += 1;
				}else if(curr_applicator.offsets[i].from == "front" && curr_applicator.offsets[i].from1 == "left"){
					curr_idx = front_left_idx;
					p = {x: panel_origin.x + pixel_per_mm*parse_offset_value(front_left_positions[curr_idx].x), y: panel_origin.y + pixel_per_mm*(panel_depth - parse_offset_value(front_left_positions[curr_idx].y))}
					p_hori = {x: panel_origin.x, y: p.y};
					p_vert = {x: p.x, y: panel_origin.y + pixel_per_mm*panel_depth};
					front_left_idx += 1;
				}

				
				
				if(p){
					var dist_hori = distance_calc((p.x + p_hori.x)/2, (p.y + p_hori.y)/2, clientX, clientY);

					if(dist_hori < tol){
						new_activeElement = {
							object: "offset",
							x: (p.x + p_hori.x)/2,
							y: (p.y + p_hori.y)/2,
							from: curr_applicator.offsets[i].from,
							from1: curr_applicator.offsets[i].from1,
							curr_from: curr_applicator.offsets[i].from1,
							idx: curr_idx,
							val: curr_applicator.offsets[i].distance1
						}
					}

					var dist_vert = distance_calc((p.x + p_vert.x)/2, (p.y + p_vert.y)/2, clientX, clientY);
					if(dist_vert < tol){
						new_activeElement = {
							object: "offset",
							x: (p.x + p_vert.x)/2,
							y: (p.y + p_vert.y)/2,
							from: curr_applicator.offsets[i].from,
							from1: curr_applicator.offsets[i].from1,
							curr_from: curr_applicator.offsets[i].from,
							idx: curr_idx,
							val: curr_applicator.offsets[i].distance
						}
					}
				}
			}
		}
		return new_activeElement;
	}

	const active_text_keyup = (e) => {
		if(e.key == "Enter"){
			var params = {panel_width, panel_depth, panel_thickness};
			if(isNaN(Number(e.target.value)) && (window.Module.validate_expression_interface(e.target.value, JSON.stringify(params)) == "failure")){
				set_activeTextBox({
					...activeTextBox,
					is_entered_text_invalid: true
				}) 
				//invalid entry
			}else{
				ui_action_callback({
					...activeTextBox,
					val: e.target.value
				});
				set_activeTextBox(null);
			}
		}
	}
	

	const mousemove = (e) => {
		if(!with_controller){
			return;
		}
		var canvas = canvasRef.current;

		if(!canvas){
			return;
		}

		var clientX = e.clientX - canvas.getBoundingClientRect().x;
		var clientY = e.clientY - canvas.getBoundingClientRect().y;

		if(!activeTextBox){
			var new_activeElement = findActiveElement(clientX, clientY)
			set_activeElement(new_activeElement);
		}
	}

	const mouseup = (e) => {
		if(!with_controller){
			return;
		}
		var canvas = canvasRef.current;

		if(!canvas){
			return;
		}

		var context = canvas.getContext('2d');

		var clientX = e.clientX - canvas.getBoundingClientRect().x;
		var clientY = e.clientY - canvas.getBoundingClientRect().y;

		var new_activeElement = findActiveElement(clientX, clientY);
		if(new_activeElement){
			if(new_activeElement.object == "offset"){
				set_activeTextBox({
					...new_activeElement,
					x: canvas.getBoundingClientRect().x + new_activeElement.x,
					y: canvas.getBoundingClientRect().y + new_activeElement.y,
				});
			}else {
				set_activeTextBox(null);
				ui_action_callback(new_activeElement);
			}
		}else{
			set_activeTextBox(null);
		}
	}

	const parse_offset_value = (val) => {
		var params = {panel_width, panel_depth, panel_thickness};

		var new_val = 0;
		if(!isNaN(Number(val))){
			new_val = Number(val);
		}else{
			var is_valid = window.Module.validate_expression_interface(String(val), JSON.stringify(params));
			if(is_valid){
				new_val = window.Module.parse_expression_interface(String(val), JSON.stringify(params));
			}
		}

		return new_val;
	}

	const get_filtered_offsets = (from_value, from_value1) => {
		//returns array of numbers if only 1D is passed. otherwise returns points
		var result = [];

		for(var i=0;i<curr_applicator.offsets.length;i++){
			var condition = (!from_value) || (from_value && curr_applicator.offsets[i].from == from_value);
			var condition1 = (!from_value1) || (from_value1 && curr_applicator.offsets[i].from1 == from_value1);
			if(condition && condition1){
				if(from_value && from_value1){
					result.push({
						x: curr_applicator.offsets[i].distance1,
						y: curr_applicator.offsets[i].distance
					})
				}else{
					result.push(curr_applicator.offsets[i].distance)
				}
			}
		}

		return result;
	}

    const drawConnectorCursor = (context, centerX, centerY, radius, strokeColor, strokeWidth) => {
		var inner_circle_radius = radius/3;

		context.lineWidth = strokeWidth;
		context.strokeStyle = strokeColor;
		context.fillStyle = "#ffffff";

		context.beginPath();
		context.arc(centerX, centerY, inner_circle_radius, 0, 2 * Math.PI, false);
		context.fill();
		context.stroke();

		context.beginPath();
		
		context.moveTo(centerX, centerY - inner_circle_radius);
		context.lineTo(centerX, centerY - radius);
		context.moveTo(centerX + inner_circle_radius, centerY);
		context.lineTo(centerX + radius, centerY);
		context.moveTo(centerX, centerY + inner_circle_radius);
		context.lineTo(centerX, centerY + radius);
		context.moveTo(centerX - inner_circle_radius, centerY);
		context.lineTo(centerX - radius, centerY);

		context.stroke();
	}

	const draw_dimension = (context, drop_height, p1,p2, text_to_show, text_color) => {
		var angle = Math.atan2(p2.y - p1.y, p2.x - p1.x);
		var drop_height_x = Math.cos(angle - (Math.PI/2))*drop_height;
		var drop_height_y = Math.sin(angle - (Math.PI/2))*drop_height;

		context.textAlign = "center"
		context.textBaseline = "middle";
		context.fillStyle = text_color;

		var is_text_pure_number = !isNaN(Number(text_to_show));
		var text = is_text_pure_number?text_to_show:"fx"

		context.fillText(text, ((p1.x + p2.x)/2) + drop_height_x,  ((p1.y + p2.y)/2) + drop_height_y);
		var text_dim = context.measureText(text)


		context.save();
		context.fillStyle = "#000000";
		context.strokeStyle = "#0a0a0a";
		context.lineWidth = 1;
		context.beginPath();
		context.moveTo(p1.x + drop_height_x/4, p1.y + drop_height_y/4);
		context.lineTo(p1.x + drop_height_x, p1.y + drop_height_y);
		context.moveTo(p2.x + drop_height_x/4, p2.y + drop_height_y/4);
		context.lineTo(p2.x + drop_height_x, p2.y + drop_height_y);


		var final_p1 = {x: p1.x + drop_height_x, y: p1.y + drop_height_y};
		var final_p2 = {x: p2.x + drop_height_x, y: p2.y + drop_height_y};
		var center = {x: (final_p1.x + final_p2.x)/2, y: (final_p1.y + final_p2.y)/2};
		context.moveTo(final_p1.x, final_p1.y);
		context.lineTo(center.x - Math.cos(angle)*text_dim.width/2, center.y - Math.sin(angle)*text_dim.width/2);
		context.moveTo(center.x + Math.cos(angle)*text_dim.width/2, center.y + Math.sin(angle)*text_dim.width/2);
		context.lineTo(final_p2.x, final_p2.y);

		context.stroke();

		
		context.restore();

	}

	const get_pixel_per_mm = () => {
		return (0.8*canvas_width)/panel_width;
	}

	const get_panel_origin = () => {
		var pixel_per_mm = get_pixel_per_mm();

		if(curr_applicator.applicator_type == "dowel_hinge"){
			return {
				x: (canvas_width/2) - (panel_width/2)*pixel_per_mm,
				y: (canvas_height/2) - (panel_thickness/2)*pixel_per_mm
			}
		}else if(curr_applicator.applicator_type == "single_panel_connector" || curr_applicator.applicator_type == "cabinet_connector"){
			return {
				x: (canvas_width/2) - (panel_width/2)*pixel_per_mm,
				y: (canvas_height/2) - (panel_depth/2)*pixel_per_mm
			}
		}
	}

	const get_eq_position = () => {
		var panel_origin = get_panel_origin();
		var pixel_per_mm = get_pixel_per_mm();

		return {x: panel_origin.x + pixel_per_mm*(panel_width/2),  y: panel_origin.y + pixel_per_mm*panel_thickness + 5};
	}

	const get_connector_buttons = () => {
		var buttons = []
		var panel_origin = get_panel_origin();
		var pixel_per_mm = get_pixel_per_mm();
		var type = curr_applicator.applicator_type;

		var panel_thickness_in_px = pixel_per_mm*(panel_thickness);
		var button_radius = panel_thickness_in_px/4;		


		if(curr_applicator.application_method=="fixed_copies" && with_controller && (type == "dowel_hinge")){
			var front_positions = [];
			var back_positions = [];

			if(curr_applicator.equal_distance && curr_applicator.offsets.length>0){
				var repeat_distance = Math.floor(panel_width/(curr_applicator.offsets.length+1));
				for(var i=0;i<curr_applicator.offsets.length;i++){
					front_positions.push((i+1)*repeat_distance);
				}
			}else{
				front_positions = front_positions.concat(get_filtered_offsets("front"));
				back_positions = back_positions.concat(get_filtered_offsets("back"));
			}


			buttons.push({
				x: panel_origin.x - (2*button_radius),
				y: panel_origin.y,
				radius: button_radius,
				key: "add_button_front",
				display: "+",
				color:"#4597f7"
			})
			buttons.push({
				x: panel_origin.x + (2*button_radius) + panel_width*pixel_per_mm,
				y: panel_origin.y,
				radius: button_radius,
				key: "add_button_back",
				display: "+",
				color:"#4597f7"
			})

			if(front_positions.length>0){
				buttons.push({
					x: panel_origin.x - (2*button_radius),
					y: panel_origin.y + panel_thickness_in_px,
					radius: button_radius,
					key: "remove_button_front",
					display: "-",
					color:"#ff0000"
				})
			}

			if(back_positions.length>0){
				buttons.push({
					x: panel_origin.x + (2*button_radius) + panel_width*pixel_per_mm,
					y: panel_origin.y + panel_thickness_in_px,
					radius: button_radius,
					key: "remove_button_back",
					display: "-",
					color:"#ff0000"
				})
			}
		}else if(curr_applicator.application_method=="fixed_copies" && with_controller && (type == "single_panel_connector" || type == "cabinet_connector")){
			buttons.push({
				x: panel_origin.x - (2*button_radius),
				y: panel_origin.y,
				radius: button_radius,
				key: "add_button_back_left",
				display: "+",
				color:"#4597f7"
			});
			buttons.push({
				x: panel_origin.x + (2*button_radius) + panel_width*pixel_per_mm,
				y: panel_origin.y,
				radius: button_radius,
				key: "add_button_back_right",
				display: "+",
				color:"#4597f7"
			});
			buttons.push({
				x: panel_origin.x + (2*button_radius) + panel_width*pixel_per_mm,
				y: panel_origin.y + pixel_per_mm*panel_depth,
				radius: button_radius,
				key: "add_button_front_right",
				display: "+",
				color:"#4597f7"
			});
			buttons.push({
				x: panel_origin.x - (2*button_radius),
				y: panel_origin.y + pixel_per_mm*panel_depth,
				radius: button_radius,
				key: "add_button_front_left",
				display: "+",
				color:"#4597f7"
			});

			for(var i=0;i<curr_applicator.offsets.length;i++){
				var curr_from = curr_applicator.offsets[i].from;
				var curr_from1 = curr_applicator.offsets[i].from1;
				var curr_x = pixel_per_mm*parse_offset_value(curr_applicator.offsets[i].distance1);
				var curr_y = pixel_per_mm*parse_offset_value(curr_applicator.offsets[i].distance);
				if(curr_from == "front"){
					curr_y = panel_depth*pixel_per_mm - curr_y - (2*button_radius)
				}else{
					curr_y += (2*button_radius)
				}
				
				if(curr_from1 == "right"){
					curr_x = panel_width*pixel_per_mm - curr_x - (2*button_radius)
				}else{
					curr_x += (2*button_radius)
				}

				buttons.push({
					x: panel_origin.x + curr_x,
					y: panel_origin.y + curr_y,
					radius: button_radius,
					key: "remove_button_index_" + i,
					display: "-",
					color:"#ff0000"
				});
			}

		}
			

		return buttons
	}

	const drawCircledText = (context, text, pos_x, pos_y, radius, color) => {
		context.save();
		context.font = "bold " + (radius*2/1.2).toFixed(0) + "px sans-serif";
		context.fillStyle= "#ffffff";
		context.strokeStyle = color;
		context.textBaseline = "middle";
		context.beginPath();
		context.arc(pos_x, pos_y, radius, 0, 2 * Math.PI, false);
		context.fill();
		context.stroke();

		context.fillStyle = color;
		context.textAlign = "center";
		context.fillText(text, pos_x, pos_y);

		context.restore();
	}

	const draw_canvas = () => {
		if(!curr_applicator){
			console.log("no connector to draw");
            return;
        }
		
        if(!canvasRef || !(canvasRef.current)){
			console.log("could not find canvas");
            return;
        }

		// console.log("drawing with ", _deref(curr_applicator))
		var canvas = canvasRef.current;

		if(!canvas){
			return;
		}
		var context = canvas.getContext('2d');

		
		var w = canvas.width;
		var h = canvas.height;

		var panel_origin = get_panel_origin();
		var pixel_per_mm = get_pixel_per_mm();

		context.font = (((panel_thickness/2)*pixel_per_mm)).toFixed(0) + "px sans-serif";


		context.clearRect(0, 0, w, h);
		context.fillStyle = "#ffffff"
		context.strokeStyle = "#000000"
		
		var type = curr_applicator.applicator_type;

		if(type == "dowel_hinge"){
			context.fillRect(panel_origin.x, panel_origin.y, pixel_per_mm*panel_width, pixel_per_mm*panel_thickness);
			context.strokeRect(panel_origin.x, panel_origin.y, pixel_per_mm*panel_width, pixel_per_mm*panel_thickness);
		}else if(type == "single_panel_connector" || type == "cabinet_connector"){
			context.fillRect(panel_origin.x, panel_origin.y, pixel_per_mm*panel_width, pixel_per_mm*panel_depth);
			context.strokeRect(panel_origin.x, panel_origin.y, pixel_per_mm*panel_width, pixel_per_mm*panel_depth);
		}


		if(type == "dowel_hinge"){
			var front_positions = [];
			var back_positions = [];

			if(curr_applicator.application_method == "fixed_copies"){
				if(curr_applicator.equal_distance && curr_applicator.offsets.length>0){
					var repeat_distance = Math.floor(panel_width/(curr_applicator.offsets.length+1));
					for(var i=0;i<curr_applicator.offsets.length;i++){
						front_positions.push((i+1)*repeat_distance);
					}
				}else{
					front_positions = front_positions.concat(get_filtered_offsets("front"));
					back_positions = back_positions.concat(get_filtered_offsets("back"));
				}
			}else if(curr_applicator.application_method.includes("center_") && curr_applicator.repeat_distance > 0){
				let half_length = panel_width/2.0;
                let length_used = 0;

                //create as many connectors based on cam_applicator->repeat_distance
                if(curr_applicator.application_method == "center_odd"){
                    back_positions.push(half_length);
                }

                while((length_used + curr_applicator.repeat_distance) < half_length){
                    back_positions.push(half_length + length_used + curr_applicator.repeat_distance);
                    back_positions.push(half_length - length_used - curr_applicator.repeat_distance);

                    length_used += curr_applicator.repeat_distance;
                }


				// var number_of_copies = Math.floor(panel_width/curr_applicator.repeat_distance);
				// for(var i=0;i<number_of_copies;i++){
				// 	back_positions.push(curr_applicator.repeat_distance*(i+1));
				// }
			}

			front_positions = front_positions.sort((a,b) => a-b);
			back_positions = back_positions.sort((a,b) => a-b);

			var pt = {
				x: panel_origin.x,
				y: panel_origin.y + (panel_thickness/2)*pixel_per_mm
			}


			var dimension_drop_amount = dimension_drop_in_mm*pixel_per_mm;

			for(var i=0;i<front_positions.length;i++){
				var is_active = false;
				if(activeElement && activeElement.object=="offset" && activeElement.from == "front" && activeElement.idx == i){
					is_active = true;
				}

				
				var p1 = {x:pt.x,y:pt.y};
				var p2 = {x:pt.x + pixel_per_mm*parse_offset_value(front_positions[i]),y:pt.y};
				
				draw_dimension(context, (panel_thickness/2)*pixel_per_mm + (i+1)*dimension_drop_amount, p1, p2,front_positions[i]/*dist.toFixed(1)*/,is_active?"#4597f7":"#000000");
				drawConnectorCursor(context, pt.x + pixel_per_mm*parse_offset_value(front_positions[i]), pt.y, (panel_thickness/4)*pixel_per_mm,"#000000",1);
			}
			
			for(var i=0;i<back_positions.length;i++){
				var is_active = false;
				if(activeElement && activeElement.object=="offset" && activeElement.from == "back" && activeElement.idx == i){
					is_active = true;
				}

				
				var p1 = {x:pt.x + pixel_per_mm*panel_width,y:pt.y};
				var p2 = {x:pt.x + pixel_per_mm*panel_width - pixel_per_mm*parse_offset_value(back_positions[i]),y:pt.y};
				
				draw_dimension(context, (panel_thickness/2)*pixel_per_mm + (i+1)*dimension_drop_amount, p1,p2,back_positions[i]/*dist.toFixed(1)*/,is_active?"#4597f7":"#000000");
				drawConnectorCursor(context, pt.x + pixel_per_mm*panel_width - pixel_per_mm*parse_offset_value(back_positions[i]), pt.y, (panel_thickness/4)*pixel_per_mm,"#000000",1);
			}

			drawCircledText(context, "S", pt.x, pt.y, (panel_thickness/4)*pixel_per_mm, "#000000");
			drawCircledText(context, "E", pt.x + (pixel_per_mm*panel_width), pt.y, (panel_thickness/4)*pixel_per_mm, "#000000");

			//drawing EQ button
			if(curr_applicator.application_method=="fixed_copies" && (curr_applicator.equal_distance || with_controller)){
				var eq_position = get_eq_position();
				context.save();
				if(activeElement && activeElement.object == "eq_button"){
					context.fillStyle = "#4597f7";
				}else if(curr_applicator.equal_distance){
					context.fillStyle = "#000000";
				}else{
					context.fillStyle = "rgba(100,100,100,0.5)";
				}
				context.textBaseline = "top";
				context.fillText("EQ", eq_position.x,  eq_position.y);
				context.restore();
			}

		}else if(type == "single_panel_connector" || type == "cabinet_connector"){
			var back_left_positions = get_filtered_offsets("back","left");
			var back_right_positions = get_filtered_offsets("back","right");
			var front_right_positions = get_filtered_offsets("front","right");
			var front_left_positions = get_filtered_offsets("front","left");

			for(var i=0;i<back_left_positions.length;i++){
				var is_active_hori = false; var is_active_vert = false;
				if(activeElement && activeElement.object=="offset" && activeElement.from == "back"  && activeElement.from1 == "left" && activeElement.idx == i){
					if(activeElement.curr_from == "back"){
						is_active_vert = true;
					}else if(activeElement.curr_from == "left"){
						is_active_hori = true;
					}
				}

				var p = {x: panel_origin.x + pixel_per_mm*parse_offset_value(back_left_positions[i].x), y: panel_origin.y + pixel_per_mm*parse_offset_value(back_left_positions[i].y)};
				draw_dimension(context, 0, p , {x: panel_origin.x, y: p.y},back_left_positions[i].x,is_active_hori?"#4597f7":"#000000");
				draw_dimension(context, 0, p , {x: p.x, y: panel_origin.y},back_left_positions[i].y,is_active_vert?"#4597f7":"#000000");

				drawConnectorCursor(
					context,
					p.x,
					p.y,
					(panel_thickness/3)*pixel_per_mm,"#000000",1
				);

			}

			for(var i=0;i<back_right_positions.length;i++){
				var is_active_hori = false; var is_active_vert = false;
				if(activeElement && activeElement.object=="offset" && activeElement.from == "back"  && activeElement.from1 == "right" && activeElement.idx == i){
					if(activeElement.curr_from == "back"){
						is_active_vert = true;
					}else if(activeElement.curr_from == "right"){
						is_active_hori = true;
					}
				}

				var p = {x: panel_origin.x + pixel_per_mm*(panel_width - parse_offset_value(back_right_positions[i].x)),y: panel_origin.y + pixel_per_mm*parse_offset_value(back_right_positions[i].y)};
				draw_dimension(context, 0, p , {x: panel_origin.x + pixel_per_mm*panel_width, y: p.y},back_right_positions[i].x,is_active_hori?"#4597f7":"#000000");
				draw_dimension(context, 0, p , {x: p.x, y: panel_origin.y},back_right_positions[i].y,is_active_vert?"#4597f7":"#000000");
				
				drawConnectorCursor(
					context,
					p.x,
					p.y,
					(panel_thickness/3)*pixel_per_mm,"#000000",1
				);

			}

			for(var i=0;i<front_right_positions.length;i++){
				var is_active_hori = false; var is_active_vert = false;
				if(activeElement && activeElement.object=="offset" && activeElement.from == "front"  && activeElement.from1 == "right" && activeElement.idx == i){
					if(activeElement.curr_from == "front"){
						is_active_vert = true;
					}else if(activeElement.curr_from == "right"){
						is_active_hori = true;
					}
				}

				var p = {x: panel_origin.x + pixel_per_mm*(panel_width - parse_offset_value(front_right_positions[i].x)), y: panel_origin.y + pixel_per_mm*(panel_depth - parse_offset_value(front_right_positions[i].y))}
				draw_dimension(context, 0, p , {x: panel_origin.x + pixel_per_mm*panel_width, y: p.y},front_right_positions[i].x,is_active_hori?"#4597f7":"#000000");
				draw_dimension(context, 0, p , {x: p.x, y: panel_origin.y + pixel_per_mm*panel_depth},front_right_positions[i].y,is_active_vert?"#4597f7":"#000000");
				
				drawConnectorCursor(
					context,
					p.x,
					p.y,
					(panel_thickness/3)*pixel_per_mm,"#000000",1
				);

			}

			for(var i=0;i<front_left_positions.length;i++){
				var is_active_hori = false; var is_active_vert = false;
				if(activeElement && activeElement.object=="offset" && activeElement.from == "front"  && activeElement.from1 == "left" && activeElement.idx == i){
					if(activeElement.curr_from == "front"){
						is_active_vert = true;
					}else if(activeElement.curr_from == "left"){
						is_active_hori = true;
					}
				}

				var p = {x: panel_origin.x + pixel_per_mm*parse_offset_value(front_left_positions[i].x), y: panel_origin.y + pixel_per_mm*(panel_depth - parse_offset_value(front_left_positions[i].y))}
				draw_dimension(context, 0, p , {x: panel_origin.x, y: p.y},front_left_positions[i].x,is_active_hori?"#4597f7":"#000000");
				draw_dimension(context, 0, p , {x: p.x, y: panel_origin.y + pixel_per_mm*panel_depth},front_left_positions[i].y,is_active_vert?"#4597f7":"#000000");
				
				drawConnectorCursor(
					context,
					p.x,
					p.y,
					(panel_thickness/3)*pixel_per_mm,"#000000",1
				);

			}
		}

		if(with_controller && curr_applicator.application_method == "fixed_copies"){
			var connector_buttons = get_connector_buttons();
			var active_name = activeElement&&activeElement.object?activeElement.object:""
			
			for(var i=0;i<connector_buttons.length;i++){
				var curr_button = connector_buttons[i];
				drawCircledText(context, curr_button.display, curr_button.x, curr_button.y, (active_name==curr_button.key?1.5:1)*curr_button.radius, curr_button.color);
			}
		}
	}

    return (
		<React.Fragment>
			<canvas onMouseMove={mousemove} onMouseUp={mouseup} ref={canvasRef} width={canvas_width} height={canvas_height} style={{width:canvas_width,height:canvas_height}}></canvas>
			{activeTextBox?(
				<input type="text" defaultValue={activeTextBox.val} onKeyUp={active_text_keyup} style={{backgroundColor:activeTextBox.is_entered_text_invalid?"#e6aeb3":"white", textAlign:"center",fontSize:12,width:80,height:27,position:"fixed",left:activeTextBox.x - 40,top:activeTextBox.y - 13}} />
			):''}
		</React.Fragment>
    )
}


export default ConnectorCanvasComponent;